import React, { Fragment } from 'react'
import TeamTwo from './TeamTwo'

const teamContent = [

    {
        img: 'Sulochana Hakel Group Director Sales and Marketing.png',
        desig: 'Group Director - Sales and Marketing',
        name: 'Sulochana Perera',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '50',
    },
    {
        img: 'Sanjeewa Ranasinghe Assistant Director Finance.png',
        desig: 'Assistant Director - Finance ',
        name: 'Sanjeewa Ranasinghe',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '50',
    },
    {
        img: 'Amila Thirimanna Director Operations AVIHA Pharmaceutical & Health Care Sector.jpg',
        desig: 'Director Operations - AVIHA Pharmaceutical & Health Care Sector',
        name: 'Amila Thirimanne',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '50',
    },
    {
        img: 'Yasanga Bandara Director Projects.jpg',
        desig: 'Director - Projects',
        name: 'Yasanga Bandara',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '150',
    },
    {
        img: 'Mohamed Rushdi Director Operation Jobdaddy.jpg',
        desig: 'Director Operation - Jobdaddy.lk ',
        name: 'Mohamed Rushdi',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '150',
    },
    {
        img: 'Saumi Rangedara Manager Human Resources.jpg',
        desig: 'Manager - Human Resources',
        name: 'Saumi Rangedara',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '50',
    },
    {
        img: 'Binoy Perera Group Skills Development Director CBBS.jpg',
        desig: 'Group Skills Development Director - CBBS',
        name: 'Binoy Perera',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '100',
    },
    {
        img: 'G.H. Chamalika Assistant General Manager CCHSS.jpg',
        desig: 'Assistant General Manager - CCHSS',
        name: 'G.H. Chamalika',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '100',
    },
    {
        img: 'Nishantha Bandara Assistant General Manager CBBS (Walukarama Branch).png',
        desig: 'Assistant General Manager - CBBS (Walukarama Branch)',
        name: 'Nishantha Bandara',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '100',
    },
    {
        img: 'Ishan Sameera Zoysa Assistant General Manager CBBS (Alfred Place Branch).jpg',
        desig: 'Assistant General Manager - CBBS (Alfred Place Branch)',
        name: 'Ishan Sameera Zoysa ',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '100',
    },
   
   
    {
        img: 'Chamari Herath Senior Manager Facilities and Procurement.png',
        desig: 'Senior Manager - Facilities and Logistics',
        name: 'Chamari Herath',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '150',
    },
    {
        img: 'Madhusanka Wijesinghe IT Manager.png',
        desig: 'Manager - IT ',
        name: 'Madhusanka Wijesinghe',
        social: [
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos: 'fade-up',
        dataDelay: '150',
    },
   
   
   
    
    
]

const TeamTwoA = () => {
    return (
        <Fragment>
            <div className="container">
                <div className="row gx-4 gx-xxl-5 mt-50">
                    <div className="col-lg-12">
                        <div className="section-title-two text-center mb-55">
                            <h1 className="sub-title mb-20">Leadership Team</h1>
                        </div>
                    </div>
                </div>
                <TeamTwo />

            </div>

            <div className="row gx-3 gx-xxl-5 justify-content-center mt-50">
                
                {teamContent.map((val, i) => (
                    <div key={i} className="col-lg-3 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                        <div className="team-style-two text-center mb-45">
                            <div className="team">
                            <a><img style={{height:'300px', width:'225px'}} src={`assets/img/team/${val.img}`} alt="img"/></a>

                            </div>
                            <div className="team-content">
                            <h3 className="sect-title-two">
                                    <a>{val.name}</a>
                                </h3>
                                <p>{val.desig}</p>
                               
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default TeamTwoA